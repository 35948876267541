import {useEffect, useState} from 'react';
import {useViewability} from '@shopify/dux';

import Modal, {ModalColorScheme} from '@/components/shared/Modal/Modal';
import {ModalSizeEnum} from '@/enums';
import {removeHash, usePushState} from '@/hooks/usePushState';
import Typography from '@/components/base/elements/Typography/Typography';
import type {RevenueValues} from '@/components/shared/BaseLeadForm/fields/constants';
import type {ActionResult} from '@/components/shared/BaseLeadForm/types';
import ShopifyLogo from '@/components/base/elements/Logo/ShopifyLogo';
import {
  ShopifyLogoColor,
  ShopifyLogoTheme,
} from '@/components/base/elements/Logo/types';
import CheckIcon from '@/components/plus/section/ContactForm/CheckIcon';

import type {RequestDemoFormProps} from './RequestDemoForm';
import RequestDemoForm from './RequestDemoForm';
import type {RequestDemoFormSuccessProps} from './RequestDemoFormSuccess';
import {RequestDemoFormSuccess} from './RequestDemoFormSuccess';

const DRIFT_INTERACTION_ID = 391505;

interface RequestDemoModalProps {
  content: {
    headingHtml: string;
    subheadHtml: string;
    infoList: string[];
  };
  form: RequestDemoFormProps['content'];
  formSuccess: RequestDemoFormSuccessProps['content'];
  directMarketingCampaignId: string;
}

export default function RequestDemoModal({
  content,
  form,
  formSuccess,
  directMarketingCampaignId,
}: RequestDemoModalProps) {
  const {targetMatched} = usePushState({targetHash: '#request-demo'});
  const [viewableRef] = useViewability();

  useEffect(() => {
    /**
     * PREVENT SCROLL JUMPING
     * --
     * Listen to the target hash to detect the modal opening and closing
     * to ensure the scroll position is maintained if it is below the fold
     */
    if (targetMatched) {
      // If the modal is open:
      // save the current scroll positon on the document element
      const scrollTop = document.documentElement.scrollTop
        ? document.documentElement.scrollTop
        : document.body.scrollTop;

      document.documentElement.style.top = `${-scrollTop}px`;
    } else {
      // If the modal is closed:
      // collect the scroll position from the document element and reset
      const scrollTop = document.documentElement.style.top;
      window.scrollTo(0, parseInt(scrollTop || '0', 10) * -1);
    }
  }, [targetMatched]);

  const [formResponse, setFormResponse] = useState<{
    success: boolean;
    revenue: RevenueValues | undefined;
  }>({success: false, revenue: undefined});

  function handleFormSubmitSuccess({status, formHash}: ActionResult) {
    setFormResponse({
      success: status === 'success',
      revenue: formHash?.['annualRevenueRange'] as RevenueValues,
    });
  }

  return (
    <Modal
      handleClose={removeHash}
      id="request-demo-modal"
      isActive={targetMatched}
      modalSize={ModalSizeEnum.Full}
      colorScheme={ModalColorScheme.Dark}
    >
      <section
        className="pb-36"
        data-component-name="request-demo-form"
        ref={viewableRef}
      >
        {formResponse.success ? (
          <RequestDemoFormSuccess
            annualRevenue={formResponse.revenue}
            content={formSuccess}
            driftInteractionId={DRIFT_INTERACTION_ID}
          />
        ) : (
          <div className="flex flex-col sm:flex-row gap-8 sm:gap-28">
            <div className="sm:basis-5/12">
              <ShopifyLogo
                className="absolute left-0 -top-24"
                logoTheme={ShopifyLogoTheme.White}
                logoWithText={true}
                textColor={ShopifyLogoColor.White}
                height="36"
              />
              <Typography as="h1" size="t1" className="font-extrabold mb-8">
                {content.headingHtml}
              </Typography>
              <ul className="grid gap-y-2 mb-6 sm:mb-8">
                {content.infoList &&
                  content.infoList.map((item: string, idx: number) => (
                    <li key={`${item}-${idx}`} className="flex">
                      <CheckIcon className="mr-2 mt-1 sm:mt-1.5 shrink-0" />
                      <Typography className="text-base sm:text-lg">
                        {item}
                      </Typography>
                    </li>
                  ))}
              </ul>
              <Typography>{content.subheadHtml}</Typography>
            </div>
            <div className="sm:basis-7/12">
              <RequestDemoForm
                handleFormSubmitSuccess={handleFormSubmitSuccess}
                content={form}
                driftInteractionId={DRIFT_INTERACTION_ID}
                directMarketingCampaignId={directMarketingCampaignId}
              />
            </div>
          </div>
        )}
      </section>
    </Modal>
  );
}
