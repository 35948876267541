import {RevenueValues} from '@/components/shared/BaseLeadForm/fields/constants';
import Button from '@/components/base/elements/Button/Button';
import useSignup from '@/hooks/useSignup';
import type {CardProps} from '@/components/base/modules/Card/Card';
import Card from '@/components/base/modules/Card/Card';
import {IconEnum} from '@/enums';
import {useSiteData} from '@/hooks/useSiteData';
import Typography from '@/components/base/elements/Typography/Typography';
import useGlobalNav from '@/hooks/navigation/global';
import Link from '@/components/base/elements/Link/Link';
import {helpUrl} from '@/hooks/navigation/utils';
import type {DriftWindow} from '@/components/shared/Driftbot/types';

export interface RequestDemoFormSuccessProps {
  content: {
    freeTrial: {
      headingHtml: string;
      subheadHtml: string;
      support: {
        headingHtml: string;
        subheadHtml: string;
        link: string;
      };
    };
    contact: {
      headingHtml: string;
      subheadHtml: string;
      cards: CardProps[];
    };
  };
  annualRevenue?: RevenueValues;
  driftInteractionId: number;
}

export function RequestDemoFormSuccess({
  content,
  annualRevenue,
  driftInteractionId,
}: RequestDemoFormSuccessProps) {
  const isGettingStarted = [
    RevenueValues.GETTING_STARTED,
    RevenueValues.BETWEEN_1_250K,
    RevenueValues.BETWEEN_250K_2M,
  ].includes(annualRevenue as RevenueValues);

  const {signupUrl, signupName} = useSignup();
  const {signupText} = useGlobalNav();
  const {site, getUrl} = useSiteData();

  const cards = [
    {
      icon: IconEnum.Messages,
      action: {
        onClick: () => {
          const driftWindow = window as DriftWindow;
          driftWindow.drift.api.startInteraction({
            interactionId: driftInteractionId,
          });
        },
      },
    },
    {icon: IconEnum.SalesUp, action: {href: getUrl('/compare/tco')}},
    {
      icon: IconEnum.BlogPost,
      action: {href: helpUrl(site)},
    },
  ];

  return (
    <div className="text-center">
      {isGettingStarted ? (
        <>
          <Typography as="h2" size="t1">
            {content.freeTrial.headingHtml}
          </Typography>

          <Typography className="max-w-3xl mx-auto mt-4 mb-6" size="body-lg">
            {content.freeTrial.subheadHtml}
          </Typography>

          <Button
            href={signupUrl}
            mode="dark"
            componentName={`adding-sales-cta-exp-${signupName}`}
            className="py-2 px-5 text-base font-bold tracking-[-0.01em] whitespace-nowrap"
          >
            {signupText}
          </Button>

          <div className="max-w-5xl mx-auto rounded-2xl p-lg mt-2xl bg-[linear-gradient(211deg,rgba(255,255,255,0.1)_0%,rgba(255,255,255,0.04)_103.85%)]">
            <Typography size="t4">
              {content.freeTrial.support.headingHtml}
            </Typography>

            <Typography className="mt-4 mb-6">
              {content.freeTrial.support.subheadHtml}
            </Typography>

            <Link
              href={helpUrl(site)}
              mode="dark"
              componentName="plus-free-trial-exp-help-center-button"
            >
              {content.freeTrial.support.link}
            </Link>
          </div>
        </>
      ) : (
        <>
          <Typography as="h2" size="t1">
            {content.contact.headingHtml}
          </Typography>

          <Typography className="max-w-3xl mx-auto mt-4 mb-6" size="body-lg">
            {content.contact.subheadHtml}
          </Typography>

          <div className="flex flex-wrap justify-center pt-xl gap-6">
            {content.contact.cards.map((item, idx) => {
              const href = cards[idx].action?.href
                ? {href: cards[idx].action?.href}
                : {};
              const onClick = cards[idx].action?.onClick
                ? {
                    onClick: cards[idx].action?.onClick,
                    className: 'cursor-pointer',
                  }
                : {};
              return (
                <Card
                  key={cards[idx].icon}
                  headingGroup={item.headingGroup}
                  icon={{
                    icon: cards[idx].icon,
                    size: 3,
                    className:
                      'bg-lime-30 text-black w-3xl h-3xl md:p-5 p-md rounded-2xl',
                  }}
                  mode="light"
                  type="outline"
                  size="small"
                  className="group shadow-md max-w-[384px] text-pretty bg-white text-left"
                  link={{text: item?.link?.text, ...href, ...onClick}}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
