import {withZod} from '@remix-validated-form/with-zod';
import {z} from 'zod';

import {
  DirectMarketingCampaignStatuses,
  FormSourceTypes,
  FormTypes,
  ProductInterests,
} from '@/enums';
import AnnualRevenueRangeSelect, {
  annualOnlineRevenueRangeValidation,
} from '@/components/shared/BaseLeadForm/fields/AnnualRevenueRangeSelect';
import PhoneTextInput, {
  phoneValidator,
} from '@/components/shared/BaseLeadForm/fields/PhoneTextInput';
import CompanyTextInput, {
  companyValidator,
} from '@/components/shared/BaseLeadForm/fields/CompanyTextInput';
import NameTextInput, {
  nameValidator,
} from '@/components/shared/BaseLeadForm/fields/NameTextInput';
import EmailTextInput, {
  emailValidator,
} from '@/components/shared/BaseLeadForm/fields/EmailTextInput';
import WebsiteTextInput, {
  websiteValidator,
} from '@/components/shared/BaseLeadForm/fields/WebsiteTextInput';
import {ModalColorScheme} from '@/components/shared/Modal/Modal';
import BaseLeadForm from '@/components/shared/BaseLeadForm/BaseLeadForm';
import type {ActionResult} from '@/components/shared/BaseLeadForm/types';
import {Select} from '@/components/shared/validatedForm/legacy/Select';
import {getCountriesList} from '@/components/plus/shared/forms/countries';
import {useSiteData} from '@/hooks/useSiteData';
import Checkbox from '@/components/base/elements/Checkbox/Checkbox';
import Typography from '@/components/base/elements/Typography/Typography';
import {buttonStyles} from '@/components/base/elements/Button/styles';

export interface RequestDemoFormProps {
  handleFormSubmitSuccess: (data: ActionResult) => void;
  content: {
    country: {
      label: string;
    };
    agency: {
      label: string;
    };
    chat: {
      heading: string;
      button: string;
    };
  };
  driftInteractionId: number;
  directMarketingCampaignId: string;
}

const schema = {
  ...nameValidator,
  ...emailValidator,
  ...phoneValidator,
  ...companyValidator,
  ...annualOnlineRevenueRangeValidation,
  ...websiteValidator,
  country: z
    .string()
    .min(1, {message: 'request_demo:requestDemo.form.country.error'}),
  source: z.string(),
  primaryProduct: z.string(),
  isAgency: z.string().optional(),
};

const requestDemoFormValidator = withZod(z.object(schema));

export {requestDemoFormValidator};

export default function RequestDemoForm({
  handleFormSubmitSuccess,
  content,
  driftInteractionId,
  directMarketingCampaignId,
}: RequestDemoFormProps) {
  const {site} = useSiteData();
  return (
    <>
      <BaseLeadForm
        colorScheme={ModalColorScheme.Dark}
        attributionProps={{
          primaryProduct: ProductInterests.Plus,
          source: FormSourceTypes.Contact,
          directMarketingCampaignId: directMarketingCampaignId,
          directMarketingCampaignStatus:
            DirectMarketingCampaignStatuses.FilledOutForm,
          formType: FormTypes.Contact,
        }}
        formValidator={requestDemoFormValidator}
        className="pb-7 sm:pb-10"
        onFormSubmitSuccess={handleFormSubmitSuccess}
        marketingCommunicationConsent="Implicit"
      >
        <NameTextInput />
        <EmailTextInput />
        <PhoneTextInput />
        <CompanyTextInput />
        <WebsiteTextInput />
        <div className="flex flex-col md:flex-row gap-x-gutter">
          <Select
            id="country"
            name="country"
            label={content.country.label}
            options={getCountriesList(site.locale)}
            divider
          />
          <AnnualRevenueRangeSelect type="online" legacyField />
        </div>
        <div className="flex items-center gap-x-xs px-sm py-xs rounded mb-lg bg-[linear-gradient(80deg,rgba(255,255,255,0.1)_41%,rgba(255,255,255,0.06)_72%)]">
          <Checkbox
            label={content.agency.label}
            aria-describedby={content.agency.label}
            id="isAgency"
            name="isAgency"
            className="bg-shade-05"
            mode="dark"
          />
          <Typography as="label" htmlFor="isAgency">
            {content.agency.label}
          </Typography>
        </div>
      </BaseLeadForm>
      <div className="py-7 sm:py-10 border-t border-t-[#71717A]">
        <h2 className="text-lg sm:text-xl font-medium mb-7 sm:mb-6">
          {content.chat.heading}
        </h2>
        <button
          className={`drift-open-chat ${buttonStyles({
            mode: 'dark',
            intent: 'secondary',
            border: true,
          })}`}
          data-interaction-id={driftInteractionId}
        >
          {content.chat.button}
        </button>
      </div>
    </>
  );
}
