import {EXP_ADDING_SALES_CTAS} from '@/experiments';
import {useExperiment} from '@/hooks/useExperiment';
import {useSiteData} from '@/hooks/useSiteData';

export const useAddingSalesCtas = () => {
  const variant = useExperiment(EXP_ADDING_SALES_CTAS);
  const {site, enPath} = useSiteData();

  const isPricing = enPath === '/pricing' && site.locale === 'en';
  const isFreeTrial =
    enPath === '/free-trial' && ['en', 'en-CA'].includes(site.locale);

  return {
    isAddingSalesCtasExperiment:
      (isPricing || isFreeTrial) && variant === 'treatment',
  };
};
